<template>
  <div class='CourseDiscuss'>
    <!-- 文章评论管理 -->
    <div class="navC">
      <p>公众专题素材编辑</p>
      <div class="df">
        <el-button size="medium" type="success" @click="clickBtn">保存</el-button>
        <el-button size="medium" @click="goPrev">取消</el-button>
      </div>
    </div>
    <div class="contentBox">
      <div class="mainBox">
        <div class="leftBox">
          <div class="left1Box">
            <div class="ulBox">
              <div class="liBox cp" v-for="(item, index) in article_list" :key="index">
                <div class="top" v-if="index == 0">
                  <div class="topLeft" @click="changeActive(index)"
                    :class="active == 0 ? 'activeSty' : active == 1 ? 'active2' : ''">
                    <img v-if="item.thumb" class="imgBig" :src="item.thumb" alt="">
                    <div class="aboutTxt">
                      <p class="ellipsis-1">{{ item.title || '标题' }}</p>
                    </div>
                  </div>
                  <div class="topRight">
                    <div>
                      <div class="cp hoverBlue">
                        <i class="fz24 el-icon-document-copy" @click="openTab1"></i>
                      </div>
                      <div class="cp mt6 hoverBlue">
                        <i class="fz24 el-icon-bottom" @click="moveDown(index)"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bom df" v-else>
                  <div class="bomLeft fsbc" @click="changeActive(index)" :class="active == index ? 'activeSty' : ''">
                    <div class="txtBox">
                      <p class="ellipsis-2">{{ item.title || '标题' }}</p>
                    </div>
                    <div class="imgBox">
                      <img v-if="item.thumb" class="imgBig" :src="item.thumb" alt="">
                      <i v-else class="el-icon-picture-outline fz58 c999 pubborder"></i>
                    </div>
                  </div>
                  <div class="bomRight">
                    <div>
                      <div class="cp hoverBlue">
                        <i class="fz24 el-icon-top" @click="moveUp(index)"></i>
                      </div>
                      <!-- 删除 -->
                      <div class="cp mt6 hoverBlue">
                        <i class="fz24 el-icon-delete" @click="delLi(index, item)"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="addLiSty" v-if="article_list.length < 8">
              <el-dropdown class="mt16" @command="handleCommand" placement="bottom">
                <span class="el-dropdown-link cp">
                  + 新建图文
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1" icon="el-icon-document">写新图文</el-dropdown-item>
                  <el-dropdown-item :command="2" icon="el-icon-document-copy">引用工作室原创</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>

        <!-- 预览框 -->
        <div class="rightBox">
          <div class="titleBox">
            <div class="flex_align_center">
              <span class="fz14 width60">标题：</span>
              <el-input v-model="article_list[active].title" placeholder="请输入标题" :maxlength="64"
                show-word-limit></el-input>
            </div>
            <div class="flex_align_center">
              <span class="fz14 width60">作者：</span>
              <el-input class="mt10" v-model="article_list[active].author" :maxlength="8" show-word-limit
                placeholder="请输入作者"></el-input>
            </div>

          </div>
          <div class="txtBox">
            <p v-html="format(article_list[active].content)"></p>
          </div>
        </div>

        <!-- 编辑器 -->
        <div class="centerBox">
          <div class="editorBox">
            <vue-ueditor-wrap v-model="article_list[active].content" :config="config"></vue-ueditor-wrap>
          </div>
          <div class="upBox">
            <h4>封面和摘要</h4>
            <div class="df">
              <div class="imgBox">
                <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi"
                  :dcConfig="dcConfig" @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false"
                  accept=".bmp,.png,.jpeg,.jpg,.gif" v-if="isUpOk">
                  <!-- 上传后图片div -->
                  <div v-if="article_list[active].thumb" class="avatar">
                    <img :src="article_list[active].thumb" />
                  </div>
                  <!-- 没上传div -->
                  <div v-else class="jh">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </dc-upload>
              </div>
              <div class="txtBox mt10">
                <el-input type="textarea" class="width277 ml10" :autosize="{ minRows: 3.8, maxRows: 3.8 }"
                  placeholder="请输入摘要" v-model="article_list[active].digest" :maxlength="120" show-word-limit>
                </el-input>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- 引用工作室原创文章弹框 -->
    <el-dialog class="diaSty" title="引用工作室原创文章" width="1000px" :visible.sync="TabVisible" @close="onClose">
      <div class="roleBox">
        <el-form :inline="true" :model="copyObj" class="demo-form-inline">
          <el-form-item label="">
            <el-input v-model="copyObj.keyword" clearable placeholder="请输入标题关键字"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button @click="chongzhi">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="dataList.data" border style="width: 100%">
          <el-table-column align="center" prop="title" label="文章标题" :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column align="center" prop="username" label="发布人" width="120" :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column align="center" prop="view_count" label="浏览量" width="120" :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column align="center" prop="create_time" label="发布时间" width="180" :show-overflow-tooltip='true'>
          </el-table-column>
          <el-table-column align="center" label="选择" width="120">
            <template scope="scope">
              <el-button type="text" @click="addOldLi(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="tc mt20" background @current-change="handleSearch" :current-page.sync="copyObj.page"
          :page-size="copyObj.limit" layout="prev, pager, next, jumper" :total="dataList.total">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as wechatApi from "@/api/teacherStudio/wechatApi"
export default {
  data() {
    return {
      config: {
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: "500", // 初始容器高度
        initialFrameWidth: "100%", // 初始容器宽度
        UEDITOR_HOME_URL: "/UEditor/",
        serverUrl: `/api/upload/bdUpload`, //后端配置的上传接口（接口返回配置只需要一个oobj，不需要外层内容）
        toolbars: [[
          'undo', 'redo', '|',
          'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
          'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
          'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
          'directionalityltr', 'directionalityrtl', 'indent', '|',
          'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
          'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
          'simpleupload', 'insertimage', 'emotion', 'insertcode', 'inserttable',
        ]],
      },
      group_id: "",
      del_article_id_array: [],
      article_list: [
        {
          edit_id: "",
          title: "",
          content: "",
          thumb: "",
          author: "",
          digest: "",
        },
      ],
      active: 0,
      isUpOk: false,
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      TabVisible: false,
      copyObj: {
        keyword: "",
        limit: 6,
        page: 1,
      },
      dataList: [],
      activeName: '1',
    }
  },
  created() {
    this._getAddress()
    if (this.$route.query.id) {
      this.handleGetEditInfo()
    }
  },
  mounted() {

  },
  computed: {
  },
  methods: {
    // 获取编辑信息
    handleGetEditInfo() {
      wechatApi.det_materialArticle({ view_group_id: this.$route.query.id, studio_id: localStorage.getItem('studioId') }).then(res => {
        let obj = res.data.data
        this.group_id = obj.group_id
        this.article_list = obj.group_list
      })
    },
    clickBtn() {
      let param = {
        group_id: this.group_id,
        del_article_id_array: this.del_article_id_array,
        article_list: JSON.parse(JSON.stringify(this.article_list)),
        studio_id: localStorage.getItem('studioId')
      }
      param.article_list.forEach((item, index) => {
        item.edit_id = item.id || ''
        item.sort = index + 1
      })
      console.log(param);
      // return
      wechatApi.add_materialArticle(param).then(res => {
        this.goPrev()
      })
    },
    handleCommand(command) {
      if (command == 1) {
        this.article_list.push({
          edit_id: "",
          title: "",
          content: "",
          thumb: "",
          author: "",
          digest: ""
        })
        this.changeActive(this.article_list.length - 1)
      }
      if (command == 2) {
        this.tabType = 2
        this._copyFamousList()
      }
    },
    _copyFamousList() {
      this.copyObj.studio_id = localStorage.getItem('studioId')
      wechatApi.copyFamousList(this.copyObj).then(res => {
        this.dataList = res.data.data
        this.TabVisible = true
      })
    },
    handleSearch(val) {
      this.copyObj.page = val;
      this._copyFamousList(2);
    },
    onClose() {
      this.TabVisible = false
    },
    // 引用
    openTab1() {
      this.tabType = 1
      this._copyFamousList()
    },
    onSubmit() {
      this.copyObj.page = 1
      if (this.tabType == 1) {
        this.openTab1()
      } else {
        this._copyFamousList();
      }
    },
    chongzhi() {
      this.copyObj = {
        keyword: "",
        limit: 6,
        page: 1,
      }
      this._copyFamousList()
    },
    addOldLi(row) {
      wechatApi.copyFamousDetail({ studio_id: localStorage.getItem('studioId'), copy_id: row.id }).then(res => {
        let obj = res.data.data
        if (this.tabType == 1) {
          this.article_list[0].title = obj.title
          this.article_list[0].content = obj.content
          this.article_list[0].thumb = obj.thumb
          this.article_list[0].author = obj.name
          this.article_list[0].digest = obj.digest
          this.TabVisible = false
        } else {
          this.article_list.push({
            edit_id: "",
            title: obj.title,
            content: obj.content,
            thumb: obj.thumb,
            author: obj.name,
            digest: obj.digest,
          })
          this.TabVisible = false
          this.changeActive(this.article_list.length - 1)
        }
      })
    },
    changeActive(val) {
      this.active = val
    },
    // 上移
    moveUp(index) {
      const arr = this.article_list
      arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]))
    },
    // 下移
    moveDown(index) {
      const arr = this.article_list
      arr.splice(index, 1, ...arr.splice(index + 1, 1, arr[index]))
    },
    // 删除列表
    delLi(index, item) {
      if (item.id) {
        this.del_article_id_array.push(item.id)
      }
      if (this.active >= index) {
        this.active = this.active - 1
      }
      this.article_list.splice(index, 1)
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      if (!data.upload) return;
      // 得到上传文件的数据list
      let resData = data.upload.CompleteMultipartUploadResult;
      //去掉resData.ETag属性的双引号
      resData.ETag = resData.ETag.replace(/"/g, "");
      let etag = resData.ETag;
      //请求erg获取上传文件返回的etagid
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      this.article_list[this.active].thumb = resData.Location
      // this.form.photo_url = resData.Location;
    },
    // 返回上一页
    goPrev() {
      this.$router.go(-1)
    },
    format(html) {
      var GRT = [
        // img 样式
        ['img', "max-width:100%;height:auto;"],
      ];
      for (let i = 0; i < GRT.length; i++) {
        html = html.replace(new RegExp('<' + GRT[i][0] + '>|<' + GRT[i][0] + ' (.*?)>', 'gi'), function (word) {
          // 分析 dom 上是否带有 style=""
          if (word.indexOf('style=') != -1) {
            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)style="(.*?)"(.*?)(/?)>', 'gi');
            return word.replace(regIn, '<' + GRT[i][0] + '$1style="$2 ' + GRT[i][1] + '"$3$4>');
          } else {
            var regIn = new RegExp('<' + GRT[i][0] + '(.*?)(/?)>', 'gi');
            return word.replace(regIn, '<' + GRT[i][0] + '$1 style="' + GRT[i][1] + '$2">');
          }
        });
      }
      return html;
    },
  },
}
</script>
<style lang="less" scoped>
.CourseDiscuss {
  width: 1400px;
  margin: 0 auto;
  margin-bottom: 20px;

  .navC {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;

    p {
      font-weight: bold;
    }
  }

  //内容盒子
  .contentBox {
    padding-top: 20px;

    .mainBox {
      display: flex;
      justify-content: space-between;

      .leftBox {
        width: 400px;
        display: flex;

        .left1Box {
          width: 100%;

          .ulBox {
            // width: 330px;
            margin-right: 10px;

            &:nth-child(3n) {
              margin-right: 0;
            }

            .liBox {

              .top {
                display: flex;

                &:hover {
                  .topRight {
                    display: flex;
                  }
                }



                .topLeft {
                  position: relative;
                  width: 330px;
                  height: 184px;
                  border-top: 1px solid #ccc;
                  border-left: 1px solid #ccc;
                  border-right: 1px solid #ccc;

                  img {
                    width: 100%;
                    height: 184px;
                  }

                  .aboutTxt {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(0deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .3));
                    width: 100%;
                    height: 32px;
                    line-height: 32px;
                    color: #fff;
                    padding: 0 10px;
                    box-sizing: border-box;
                  }

                }

                .activeSty {
                  box-shadow: 0 0 10px 1px #077be3;
                }

                .active2 {
                  box-shadow: 0px 10px 10px -10px #077be3;
                }

                .topRight {
                  display: none;
                  align-items: center;
                  margin-left: 10px;

                  >div {
                    border: 1px solid #ccc;
                    padding: 5px 5px;
                    border-radius: 20px;
                  }
                }
              }

              .bom {
                &:hover {
                  .bomRight {
                    display: flex;
                  }
                }

                .bomLeft {
                  width: 330px;
                  height: 80px;
                  padding: 10px;
                  box-sizing: border-box;
                  border-bottom: 1px solid #ccc;
                  border-left: 1px solid #ccc;
                  border-right: 1px solid #ccc;

                  .txtBox {
                    width: 240px;
                  }

                  .imgBox {
                    width: 60px;
                    height: 60px;

                    img {
                      width: 100%;
                      height: 60px;
                    }
                  }
                }

                .activeSty {
                  box-shadow: 0 0 10px 1px #077be3;
                }

                .bomRight {
                  display: none;
                  align-items: center;
                  margin-left: 10px;

                  >div {
                    border: 1px solid #ccc;
                    padding: 5px 5px;
                    border-radius: 20px;
                  }
                }
              }
            }
          }

          .addLiSty {
            width: 330px;
            height: 80px;
            padding: 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            text-align: center;
          }
        }

      }


      .centerBox {
        width: 450px;
        padding-bottom: 30px;

        .upBox {
          margin-top: 10px;

          .imgBox {
            margin-top: 10px;
            position: relative;

            // 上传图片样式
            .avatar-uploader {

              //  上传图片样式
              .avatar {
                width: 164px;
                height: 92px;
                box-sizing: border-box;

                img {
                  width: 100%;
                  height: 92px;
                }
              }

              //没上传图片样式
              .jh {
                width: 164px;
                height: 92px;
                box-sizing: border-box;
                border: 1px dashed #ebebeb;

                .avatar-uploader-icon {
                  font-size: 30px;
                  margin-top: 30px;
                }
              }
            }

            //添加一张图片之后隐藏后续框样式
            .disabled .el-upload--picture-card {
              display: none;
            }
          }
        }
      }

      .rightBox {
        width: 480px;

        .titleBox {
          background: #EEF6FF;
          // height: 60px;
          padding: 20px 10px 20px 10px;

          h4 {
            font-size: 20px;
            line-height: 60px;
          }
        }

        .txtBox {
          padding: 10px 30px;
          box-shadow: 0 0 10px 2px #eee;
          height: 615px;
          overflow: auto;
        }
      }

    }

  }
}

.diaSty {
  .roleBox1 {
    width: 280px;
    margin: 0 auto;

    .tx1 {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #333333;
      margin-bottom: 14px;

      i {
        color: #ff8201;
        font-size: 18px;
      }

      span {
        font-weight: 700;
      }
    }

    .tx2 {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      margin-bottom: 42px;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .one {
      width: 148px;
      height: 42px;
      background: #f7f7f7;
      border: 1px solid #ececec;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 700;
      color: #333333;
    }

    .two {
      width: 148px;
      height: 42px;
      background: #ff8201;
      border-radius: 6px;
      border: 1px solid #ff8201;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
    }
  }
}
</style>
